import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {MailingService} from '../../services/mailing.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.less']
})
export class LandingComponent implements OnInit, OnDestroy {

  public newsletterForm: FormGroup;
  public loading = false;
  public submitted = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private mailingService: MailingService) {
  }

  ngOnInit() {
    this.newsletterForm = this.formBuilder.group({
      email: [{value: '', disabled: this.loading}, [Validators.required, Validators.pattern('^[A-Za-z0-9._-]+@[A-Za-z0-9._-]{2,}[.][A-Za-z]{2,}$')]],
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  submit() {
    if (this.newsletterForm.invalid) {
      return;
    }

    this.loading = true;
    this.subscriptions.push(this.mailingService.signUp(this.newsletterForm.value as MailingDto).subscribe(
      () => {
        this.submitted = true;
      },
      () => {
        this.loading = false;
      },
      () => this.loading = false
    ));
  }
}
